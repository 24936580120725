* {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
}

html {
      scroll-behavior: smooth;
      scroll-padding-block-start: 30px;
}

body {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      font-family: "InterDisplay-Thin", sans-serif;
      color: var(--main-black);
      background-color: var(--main-white);
}


@font-face {
      font-family: 'InterDisplay-Thin';
      src: url('../fonts/Inter/InterDisplay-Thin.ttf') format('truetype');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'InterDisplay-ExtraLight';
      src: url('../fonts/Inter/InterDisplay-ExtraLight.ttf') format('truetype');
      font-weight: 200;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'InterDisplay-Light';
      src: url('../fonts/Inter/InterDisplay-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'InterDisplay-Regular';
      src: url('../fonts/Inter/InterDisplay-Regular.ttf') format('truetype');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'InterDisplay-Medium';
      src: url('../fonts/Inter/InterDisplay-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'InterDisplay-SemiBold';
      src: url('../fonts/Inter/InterDisplay-SemiBold.ttf') format('truetype');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'InterDisplay-Bold';
      src: url('../fonts/Inter/InterDisplay-Bold.ttf') format('truetype');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'InterDisplay-ExtraBold';
      src: url('../fonts/Inter/InterDisplay-ExtraBold.ttf') format('truetype');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'InterDisplay-Black';
      src: url('../fonts/Inter/InterDisplay-Black.ttf') format('truetype');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
}

:root {
      --main-white: #FFFFFF;
      --off-white: #929292;
      --primary-color: #111827;
      --secondary-color: #6B7280;
      --selected-color: #1B698E;
      --main-border: #d1d5db85;
      --light-border: #d1d5db1f;
      --main-black: #000;
      --mat-black: #141414;
      --main-red: #FF0000;
      --bg-color: #114A65;
      --body-color: #f4f5f7;
      --dark-pink: #8c116d;
      --orange: #c85803;
      --yellow: #FFA523;
      --pink: #FF3169;
      --violet-color: #9F49A3;
      --cyne-color: #00B4B0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
      -webkit-transition-delay: 9999s;
      transition-delay: 9999s;
}

input[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
}

.cus-scrollbar::-webkit-scrollbar {
      width: 3px;
      height: 5px;
      background-color: #ebebeb;
      border-radius: 100rem;
}

.cus-scrollbar::-webkit-scrollbar-thumb {
      background: #27447C;
      border-radius: 100rem;
}

.cus-scrollbar {
      scrollbar-color: #27447C #ebebeb;
      scrollbar-width: thin;
}

.grid-main .grid-item {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0px;
      padding: 0px;
}

.user-text-decoration-none {
      text-decoration: none !important;
}

.user-flex-all {
      display: flex;
      justify-content: space-between;
}


/* start input field design css */

.user-form-group .user-form-control input {
      border: 1px solid #818181;
      border-radius: 45px;
      width: 100%;
      padding: 10px 24px;
      background-color: var(--main-white);
      color: #757575;
      font-size: 20px;
      line-height: 26px;
      font-family: 'InterDisplay-Regular';
      -webkit-border-radius: 45px;
      -moz-border-radius: 45px;
      -ms-border-radius: 45px;
      -o-border-radius: 45px;
}

.user-form-group .user-form-control input::placeholder {
      color: #757575;
      font-size: 20px;
      line-height: 26px;
      font-family: 'InterDisplay-Light';
      opacity: 1;
}

.user-form-group .user-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 0px;
}

.user-form-control fieldset {
      border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--main-border) !important;
}

.user-form-control-textarea:focus-visible {
      outline: none !important;
}

/* end input field design css */

/* password field */

.user-input-box .user-form-lable {
      font-size: 12px;
      line-height: 17px;
      font-weight: 500;
      margin: 0px 0px 6px;
      color: var(--secondary-color);
}

.user-input-box {
      margin-bottom: 15px;
}

.user-input-box .MuiInputBase-root {
      width: 100%;
}

.user-input-box .MuiInputBase-root fieldset:hover {
      border-color: transparent;
}

.user-form-group .user-form-control-eye {
      border: 1px solid var(--main-border);
}

.user-input-box .user-form-control-eye input {
      border: 0px;
}

.user-input-box .user-form-control-eye input::placeholder {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      opacity: 1;
}

/* End password field */

.user-page-border {
      border: 1px solid var(--main-border);
      border-radius: 6px;
      padding: 20px;
}

/* start switch css */

.user-switch-main {
      margin-left: 20px;
}

.user-switch-lable .MuiTypography-root {
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-color);
      font-weight: 400;
}

/* end switch css */




/* start pagination css */

.user-pagination-main {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid var(--main-border);
      padding-top: 20px;
}

.user-pagination-main .MuiPagination-ul {
      margin-right: 20px;
}

.user-pagination-main .MuiPaginationItem-root {
      height: 20px;
      min-width: 20px;
      border-radius: 4px;
      padding: 0px;
      font-size: 12px;
      line-height: 18px;
      color: var(--secondary-color);
      font-weight: 400;
      margin: 0 4px;
}

.user-pagination-main .MuiPaginationItem-root.Mui-selected:hover {
      background-color: var(--selected-color);
      color: var(--main-white);
}

.user-pagination-main .MuiPaginationItem-root.Mui-selected {
      background-color: var(--selected-color);
      color: var(--main-white);
}

/* end pagination css */



/* start progrss bar css */

.user-progress-bar-main {
      max-width: 470px;
      width: 100%;
      position: relative;
}

.user-progress-bar-main .user-progress-bar .MuiLinearProgress-bar {
      border-radius: 8px;
      background-color: var(--selected-color);
}

.user-progress-bar-content {
      margin-top: 30px;
}

.user-progress-bar-content .user-page-title {
      font-size: 20px;
      line-height: 30px;
      color: var(--primary-color);
      font-weight: 500;
}

.user-progress-bar-text {
      position: absolute;
      right: -35px;
      top: -5px;
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-color);
      font-weight: 500;
}

/* end progrss bar css */


/* start error text css */

body .user-error-text {
      color: var(--main-red);
      font-size: 12px;
      line-height: 15px;
      font-family: "InterDisplay-Regular";
}

/* end error text css */




/* / start dropdown css / */

.user-dropdown-box .user-form-control {
      width: 100%;
      position: relative;
}

.user-dropdown-select .MuiSelect-select {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      display: flex;
      padding: 5.6px 15px;
      border-radius: 4px;
      border: 1px solid var(--main-border);
}

.user-grey-down {
      position: absolute;
      top: 17.92px;
      right: 21.92px;
      height: 10px;
      width: 10px;
      object-fit: contain;
}

.user-dropdown-box .MuiMenu-list .user-drop-menuitem {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
}


/* / end dropdown css / */


.user-row {
      grid-gap: 30px;
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
}

/* start common modal css */
.user-modal .user-modal-inner {
      padding: 30px 15px;
      width: calc(100% - 30px);
      max-height: calc(100vh - 40px);
      border: 1px solid var(--main-border);
      border-radius: 10px;
}

.user-modal .coming-modal-inner {
      max-width: 500px;
      background-image: linear-gradient(45deg, #f7d8a31a, #f5e59454);

}

.user-modal-hgt-scroll {
      max-height: calc(100vh - 194px);
      overflow: auto;
      padding: 0 5px 0px 0px;
      margin-right: -5px;
}

.admin-modal-body {
      padding: 20px;
}

.user-modal-close-icon {
      cursor: pointer;
      height: 20px;
      width: 20px;
      object-fit: contain;
}

.user-modal-header .user-close-btn {
      min-width: 30px;
      padding: 0;
      position: absolute;
      right: 5px;
      top: 10px;
}

.MuiBackdrop-root {
      background-color: rgb(0 0 0 / 70%);
}

.user-modal-footer {
      padding: 15px 0px 0px;
}

.user-modal-header .modal-close-btn {
      padding: 0px;
      min-width: 30px;
      justify-content: flex-end;
}

/* end common modal css */



/* start common table design  */

.user-table-data-flex {
      display: flex;
      align-items: center;
      width: 100%;
}

.user-table-data-btn-flex {
      display: flex;
}

.user-table-data-btn-flex .user-table-data-btn {
      min-width: 30px;
      padding: 0px;
      justify-content: flex-start;
}

.user-table-data-img {
      margin-right: 15px;
      height: 25px;
      width: 25px;
      object-fit: cover;
      border-radius: 5px;
}

.user-table-data-flex .user-table-data-text {
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      font-size: 12px;
      line-height: 18px;
      color: var(--secondary-color);
      font-weight: 400;
}

.page-table-main .table-container {
      padding-bottom: 7px;
      background-color: transparent;
      box-shadow: none;
      overflow: auto;
      max-width: calc(100vw - 0px);
}

.page-table-main .table {
      min-width: 1100px;
}

.page-table-main .table-th {
      font-size: 12px;
      line-height: 18px;
      color: var(--primary-color);
      font-weight: 500;
      padding: 10px 10px 10px 10px;
      border-bottom: 1px solid var(--main-border);
      background-color: #1b698e1f;
}

.page-table-main .table-td {
      font-size: 11px;
      line-height: 15px;
      color: var(--secondary-color);
      font-weight: 400;
      padding: 7px 10px 7px 10px;
      border-bottom: 1px solid var(--main-border);
}

.page-table-main .table-container::-webkit-scrollbar {
      width: 2px;
      height: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-track {
      background: var(--main-border);
      border-radius: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-thumb {
      background: #8888885c;
}

.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
      background: #555;
}

/* end common table design  */

.user-icon {
      height: 13px;
      width: 13px;
      object-fit: contain;
}

.card-border {
      border-radius: 5px;
      border: 1px solid var(--main-border);
      padding: 20px;
}

.common-card {
      -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), .03);
      box-shadow: 0 5px 20px rgba(173, 181, 217, .03);
      background-color: var(--main-white);
      border: 1px solid var(--main-border);
      border-radius: 10px;
      padding: 15px;
}

.user-body-overflow .MuiTooltip-tooltip {
      box-shadow: 0 5px 20px rgba(173, 181, 217, .03);
      background-color: var(--selected-color);
      border-radius: 5px;
      color: var(--main-white);
      padding: 4px 8px;
      font-size: 13px;
      line-height: 25px;
      font-weight: 500;
}

.user-body-overflow .MuiTooltip-tooltip .MuiTooltip-arrow::before {
      color: var(--selected-color);
}

.user-userlist-inner-btn-flex {
      display: flex;
      gap: 10px;
}

.user-search-box {
      position: relative;
}

.user-search-box .user-form-control input::placeholder {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      opacity: 1;
}

.user-search-grey-img {
      position: absolute;
      left: 13px;
      top: 8px;
      margin-right: 10px;
}

.user-search-box .user-form-group .user-form-control input {
      padding: 6.38px 35px;
}

.user-user-list-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
}

.user-userlist-btn-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
}

/* end user css */


/* start user globle css */
.container {
      padding: 0px 15px 0px 15px;
      width: 100%;
      margin: auto;
      max-width: 1440px;
}

.text-decoration-none {
      text-decoration: none !important;
}

.flex-all {
      display: flex;
      justify-content: space-between;
      align-items: center;
}


/* start input field design css */

.form-group .form-control input {
      border: 1px solid var(--main-border);
      border-radius: 4px;
      width: 100%;
      padding: 10px 15px;
      background-color: transparent;
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
}

.form-group .form-control input::placeholder {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 0px;
}

.form-control fieldset {
      border: 1px solid var(--main-border);
      border-radius: 2px;
}

.form-control-textarea:focus-visible {
      outline: none !important;
}

/* end input field design css */

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
      background-image: linear-gradient(45deg, #ff67cb, #ffcc67);
      border-radius: 34px;
      color: var(--main-white);
      font-size: 20px;
      line-height: 24px;
      font-family: 'InterDisplay-Medium';
      padding: 10px 30px;
      text-transform: capitalize;
      -webkit-border-radius: 34px;
      -moz-border-radius: 34px;
      -ms-border-radius: 34px;
      -o-border-radius: 34px;
}

.btn-main .btn {
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      font-family: 'InterDisplay-Medium';
      padding: 10px 15px;
      text-transform: capitalize;
}

.border-btn-main .border-btn {
      border: solid 2px transparent;
      border-radius: 34px;
      padding: 10px 27px;
      font-size: 20px;
      line-height: 24px;
      color: var(--main-black);
      font-family: 'InterDisplay-Medium';
      text-transform: capitalize;
      position: relative;
      transition: color 0.5s;
      text-decoration: none;
}

.border-btn-main .border-btn::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 1px;
      background: linear-gradient(45deg, #ff67cb, #ffcc67);
      -webkit-mask: linear-gradient(45deg, #ff67cb, #ffcc67) content-box, linear-gradient(45deg, #ff67cb, #ffcc67);
      -webkit-mask-composite: xor;
      mask: linear-gradient(45deg, #ff67cb, #ffcc67) content-box, linear-gradient(45deg, #ff67cb, #ffcc67);
      mask-composite: exclude;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
}

.common-para {
      font-size: 14px !important;
      line-height: 21px !important;
      font-weight: 400;
}

/* start switch css */

.switch-main {
      margin-left: 20px;
}

.switch-lable .MuiTypography-root {
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-color);
      font-weight: 400;
}

/* end switch css */




/* start pagination css */

.pagination-main {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 27px;
}

.pagination-main .MuiPagination-ul {
      margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
      height: 20px;
      min-width: 20px;
      border-radius: 4px;
      padding: 0px;
      font-size: 12px;
      line-height: 18px;
      color: var(--secondary-color);
      font-weight: 400;
      margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
      background-color: var(--primary-color);
      color: var(--main-white);
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
      background-color: var(--primary-color);
      color: var(--main-white);
}

/* end pagination css */



/* start progrss bar css */

.progress-bar-main {
      max-width: 470px;
      width: 100%;
      position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
      border-radius: 8px;
      background-color: var(--primary-color);
}

.progress-bar-content {
      margin-top: 30px;
}

.progress-bar-content .user-page-title {
      font-size: 20px;
      line-height: 30px;
      color: #111827;
      font-weight: 400;
}

.progress-bar-text {
      position: absolute;
      right: -35px;
      top: -5px;
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-color);
      font-weight: 500;
}

/* end progrss bar css */


/* start error text css */

.error-text {
      color: var(--main-red);
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
}

/* end error text css */




/* / start dropdown css / */

.dropdown-box .form-control {
      width: 100%;
      position: relative;
}

.dropdown-select .MuiSelect-select {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      display: flex;
      padding: 10px 15px;
      border-radius: 4px;
}

.grey-down {
      position: absolute;
      top: 17.92px;
      right: 21.92px;
      height: 10px;
      width: 10px;
      object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
}

/* / end dropdown css / */


/* start common table title */


.table-title-main .table-title-name {
      color: var(--primary-color);
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
}

.table-title-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
}

.table-search-flex {
      display: flex;
}

/* end common table title */


/* start add user modal css */
.user-modal .user-add-user-modal-inner-main {
      max-width: 500px;
}

.user-span-text {
      color: #D1D5DB;
      margin-left: 3px;
}

.user-modal .user-modal-title {
      color: var(--primary-color);
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
}

.user-modal-user-btn-flex {
      display: flex;
      justify-content: flex-end;
      padding-top: 3px;
}

.user-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
}

/* end add user modal css */



/* start delete user modal css */

.user-user-circle-img {
      width: 52px;
      height: 52px;
      object-fit: contain;
      margin: auto;
      display: flex;
}

.user-modal .user-delete-modal-inner-main {
      max-width: 450px;
}

.user-delete-modal-inner-main .user-delete-modal-title {
      color: var(--primary-color);
      font-size: 28px;
      line-height: 42px;
      font-weight: 500;
      text-align: center;
      margin: 20px 0px 16px;
}

.user-delete-modal-inner-main .user-delete-modal-para {
      color: var(--secondary-color);
      text-align: center;
      max-width: 304px;
      width: 100%;
      margin: auto;
}

.user-delete-modal-btn-flex {
      display: flex;
      justify-content: center;
      margin-top: 26px;
}

.user-delete-modal-btn-flex .user-modal-cancel-btn {
      margin-right: 15px;
}

.user-delete-modal-btn-flex .user-modal-delete-btn,
.user-delete-modal-btn-flex .user-modal-delete-btn:hover {
      color: #FFFFFF;
      background-color: #E4004D;
}

.MuiBackdrop-root-MuiModal-backdrop {
      background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */





/* start filter css */

.user-filter-main {
      position: relative;
}

.user-filter-main .MuiDrawer-paper {
      width: 100%;
      min-width: 400px;
      max-width: 400px;
}

.user-filter-header {
      background-color: #07415D;
      display: flex;
      justify-content: space-between;
      padding: 16px 25px;
      align-items: center;
}

.user-filter-close-icon {
      height: 21px;
      width: 21px;
      object-fit: contain;
}

.user-filter-header .user-filter-title {
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      font-weight: 400;
}

.user-filter-inner-main {
      padding: 20px 25px;
      background-color: #fff;
}

.user-filter-btn-main {
      display: flex;
      justify-content: flex-end;
}

.user-filter-btn-main .user-filter-cancel-btn {
      margin-right: 15px;
}


.user-filter-footer {
      border-top: 1px solid #D1D5DB;
      padding: 15px;
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0px;
      right: 0px;
}

.user-filter-checkbox-main .user-checkbox-lable {
      margin-right: 20px;
}

/* end filter css */

.user-down-icon,
.user-plus-icon {
      margin-right: 10px;
}

.user-page-title-main .user-page-title {
      color: var(--primary-color);
      font-size: 17px;
      line-height: 24px;
      font-weight: 600;
      /* position: relative;
      padding-left: 10px; */
}

/* .user-page-title-main .user-page-title::after {
      content: '';
      position: absolute;
      height: 22px;
      width: 4px;
      background-color: var(--selected-color);
      left: 0px;
} */

.user-sub-title-main .user-sub-title {
      color: var(--primary-color);
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
}

.chart-row-main {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin-top: 15px;
}

.pie-chart-main {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
}

.chart-title-main .chart-title {
      font-size: 13px;
      font-weight: 600;
      line-height: 19px;
      color: var(--bg-color);
      margin-bottom: 10px;
}

.user-sub-title-main .user-sub-title {
      color: var(--bg-color);
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      position: relative;
}

.user-sub-title-main .user-sub-title::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 35px;
      background-color: var(--bg-color);
      left: 0px;
      bottom: -2px;
}

.title-row-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0px;
}


.page-table-main .table-not-found-td {
      padding: 0px;
      border: 0px;
}

.data-not-found-main {
      box-shadow: 0 5px 20px rgba(173, 181, 217, .03);
      background-color: var(--main-white);
      border: 1px solid var(--main-border);
      border-radius: 5px;
      padding: 9px;
      width: 100%;
      margin: 10px 0px 0px;
}

.data-not-found-main .data-not-found-text {
      font-size: 12px;
      line-height: 18px;
      color: var(--primary-color);
      font-weight: 500;
      text-align: center;
}




/* start loader css */
.loader-main {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 255px);
}

.loader {
      width: 50px;
      height: 50px;
      display: inline-block;
      padding: 0px;
      text-align: left;
}

.loader span {
      position: absolute;
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: var(--bg-color);
      -webkit-animation: loader 1.5s linear infinite;
      animation: loader 1.5s linear infinite;
}

.loader span:last-child {
      animation-delay: -0.9s;
      -webkit-animation-delay: -0.9s;
}

@keyframes loader {
      0% {
            transform: scale(0, 0);
            opacity: 0.8;
      }

      100% {
            transform: scale(1, 1);
            opacity: 0;
      }
}

@-webkit-keyframes loader {
      0% {
            -webkit-transform: scale(0, 0);
            opacity: 0.8;
      }

      100% {
            -webkit-transform: scale(1, 1);
            opacity: 0;
      }
}

/* end loader css */



/* start select 2 css */
.user-select2-main {
      width: 300px;
}

.user-select2-main .select2-container--default .select2-selection--multiple {
      background-color: transparent;
      border: 1px solid var(--main-border);
}

.user-select2-main .select2-container--default.select2-container--focus .select2-selection--multiple {
      border: 1px solid var(--main-border);
      background-color: transparent;
}

.user-select2-main .select2-container .select2-search--inline .select2-search__field {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
}

.user-select2-main .select2-container--default .select2-selection--multiple .select2-selection__rendered {
      padding: 1px 5px 0px;
}

.user-select2-main .select2-container--default .select2-selection--multiple .select2-selection__choice {
      background-color: transparent;
      border: 1px solid var(--main-border);
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
}

/* end select 2 css */



/* start accordian design  */


.accordian-main .accordian-summary {
      background-color: var(--light-gray);
      min-height: 30px;
}

.accordian-main .accordian {
      margin-bottom: 10px;
      box-shadow: none;
}

.accordian-main .accordian-details {
      font-size: 12px;
      line-height: 18px;
      color: var(--secondary-color);
      font-weight: 400;
      border: 1px solid var(--main-border);
}

.accordian-main .MuiAccordion-root::before {
      background-color: transparent;
}

.accordian-main .MuiAccordionSummary-content.Mui-expanded {
      margin: 5px 0px;
}

.accordian-main .MuiAccordionSummary-content {
      font-size: 12px;
      line-height: 18px;
      color: var(--primary-color);
      font-weight: 500;
      margin: 5px 0px;
}

.accordian-main .accordian:last-child {
      margin-bottom: 0px;
}

.accordian-main .accordian.Mui-expanded {
      margin: 10px 0;
}

.accordian-main .accordian-summary.Mui-expanded {
      min-height: 30px;
      padding: 5px 16px;
}



/* end accordian design */

img[alt] {
      font-size: 10px;
}

/* start header css */

.header-main {
      position: fixed;
      top: 16px;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 99;
}

.header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 39px;
      background-color: var(--main-white);
      border-radius: 28px;
      -webkit-border-radius: 28px;
      -moz-border-radius: 28px;
      -ms-border-radius: 28px;
      -o-border-radius: 28px;

}

.sticky-header {
      backdrop-filter: blur(5px);
      filter: blur(0.5px);
      background-color: #ffffffbd;
}

.header-logo-box .header-logo {
      height: 90px;
      max-width: 90px;
      object-fit: contain;
      width: 100%;
}

.header-nav-main .header-nav-ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      padding: 0px;
      gap: 65px;
      margin: 0px;
}

.header-nav-main .header-nav-li {
      padding: 0px;
      max-width: max-content;
}

.header-nav-main .header-nav-link {
      color: var(--mat-black);
      font-size: 20px;
      line-height: 24px;
      font-family: 'InterDisplay-Regular';
      text-decoration: none;
}

.header-nav-main .header-nav-link:hover {
      color: var(--dark-pink);
}

.header-nav-main .header-nav-link:before,
.header-nav-main .header-nav-link:after {
      content: "";
      transition: 0.5s all ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      width: 0;
      height: 2px;
      background: var(--dark-pink);
}

.header-nav-main .header-nav-link:before {
      right: 0;
      top: -5px;
}

.header-nav-main .header-nav-link:after {
      left: 0;
      bottom: -5px;
}

.header-nav-main .header-nav-link:hover:before,
.header-nav-main .header-nav-link:hover:after {
      width: 100%;
      background: linear-gradient(45deg, #ff67cb, #ffcc67);
}

.header-right-main {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
}

.header-right-main .mobile-menu-btn {
      display: none;
}

/* end header css */


/* start hero section */

.hero-section {
      /* background-color: #FDF2EC; */
      background-image: url(../../assets/images/png/hero-bg.png);
      min-height: 100vh;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      padding-bottom: 225px;
}

.home-hero-main {
      margin-top: -132px;
      padding-top: 132px;
      z-index: 0;
      position: relative;
}

.hero-content-main {
      padding-top: 132px;
}

.hero-content-wrapper {
      background-color: var(--main-white);
      padding: 8px 30px;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      max-width: max-content;
      margin: 98px auto 14px;
}

.hero-content-wrapper .hero-heading {
      font-size: 22px;
      line-height: 28px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      margin: 0;
}

.hero-token-wrapper {
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      padding: 4px 21px;
      margin: 61px 0 30px;
      background-image: linear-gradient(45deg, #ff67cb, #ffcc67);
}

.hero-token-wrapper-main {
      display: flex;
      justify-content: center;
}

.hero-token-wrapper .hero-token-text {
      font-size: 20px;
      line-height: 24px;
      color: var(--main-black);
      font-family: 'InterDisplay-Medium';
      margin: 0;
}

.hero-content-main .hero-title {
      font-size: 92px;
      font-family: 'InterDisplay-Regular';
      line-height: 120px;
      color: var(--main-black);
      margin: 0;
      text-align: center;
}

.hero-title-span {
      font-size: 92px;
      font-family: 'InterDisplay-Regular';
      line-height: 120px;
      color: #662121;
}

.hero-copy-btn-wrapper {
      background-color: var(--main-white);
      margin: 0px auto 123px;
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 9px 9px 9px 17px;
      max-width: max-content;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
}

.hero-copy-btn-wrapper .hero-copy-text {
      color: var(--main-black);
      font-size: 20px;
      line-height: 24px;
      font-family: 'InterDisplay-Regular';
}

.hero-copy-btn-wrapper .hero-copy-btn,
.hero-copy-btn-wrapper .hero-copy-btn:hover {
      background-color: var(--main-black);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      height: 34px;
      min-width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.gradiant-copy-icon {
      height: 17px;
      width: 17px;
      object-fit: contain;
}

.hero-coin-img-wrapper {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: -19%;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: slideUp 3s ease-out;
      -webkit-animation: slideUp 3s ease-out;
}

.hero-coin-img {
      width: 100%;
      max-width: 549px;
      height: 550px;
      object-fit: contain;
}

@keyframes slideUp {
      from {
            transform: translateY(100%);
      }

      to {
            transform: translateY(0);
      }
}

/* end hero section */


/* start about section */
.about-us-sec {
      background-color: var(--main-white);
      padding: 150px 0px;
}


.about-us-wrapper .about-us-para {
      color: #101111;
      font-size: 25px;
      line-height: 40px;
      font-family: 'InterDisplay-Light';
      text-align: center;
}

.about-us-wrapper .about-us-title {
      color: #101111;
      font-size: 55px;
      line-height: 72px;
      font-family: 'InterDisplay-Light';
      text-align: center;
      margin-bottom: 50px;
}

.about-us-span {
      color: #662121;
      font-size: 55px;
      line-height: 72px;
      font-family: 'InterDisplay-SemiBold';
}

.about-us-main {
      z-index: 1;
      position: relative;
}

/* end about section */


/* start tokenomics section */
.tokenomics-sec-bg {
      background-image: url(../images/svg/token-bg.svg);
      min-height: 309px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 97px 0px 0;
      overflow: hidden;
      position: relative;
}

.tokenomics-sec {
      /* background-image: url(../images/svg/token-bg.svg); */
      position: relative;
      padding: 0px 0px 125px;
      background-color: #FDF2EC;
}

.tokenomics-sec-bg::after {
      content: '';
      height: 333px;
      width: 387px;
      background-image: url(../images/svg/token-coin-right.png);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 27%;
      right: 3.5%;
      z-index: -1;
      animation: moveImageRight 5s linear forwards, bounceImageRight 5s linear infinite 5s;
      -webkit-animation: moveImageRight 5s linear forwards, bounceImageRight 5s linear infinite 5s;
}

@keyframes moveImageRight {
      0% {
            right: -100%;
            /* Start off-screen horizontally */
      }

      100% {
            right: 3.5%;
            /* End position horizontally */
      }
}

@keyframes bounceImageRight {
      0% {
            top: 27%;
            /* Original position */
      }

      50% {
            top: 15%;
            /* Bounce up */
      }

      100% {
            top: 27%;
            /* Settle back to original position */
      }
}

.tokenomics-sec-bg::before {
      content: '';
      height: 333px;
      width: 387px;
      background-image: url(../images/svg/token-coin-left.png);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 27%;
      left: 3%;
      z-index: -1;
      animation: moveImageLeft 5s linear forwards, bounceImageLeft 5s linear infinite 5s;
      -webkit-animation: moveImageLeft 5s linear forwards, bounceImageLeft 5s linear infinite 5s;
}

@keyframes moveImageLeft {
      0% {
            left: -100%;
            /* Start off-screen horizontally */
      }

      100% {
            left: 3%;
            /* End position horizontally */
            /* End at the original vertical position */
      }
}

@keyframes bounceImageLeft {
      0% {
            top: 27%;
            /* Original position */
      }

      50% {
            top: 15%;
            /* Bounce down */
      }

      100% {
            top: 27%;
            /* Settle back to original position */
      }
}

.tokenomics-content-bg {
      background-color: var(--main-white);
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      padding: 28px 23px;
}

@keyframes gradient-border {
      0% {
            border: 3px solid var(--pink);
      }

      25% {
            border: 3px solid var(--cyne-color);
      }

      50% {
            border: 3px solid var(--orange);
      }

      75% {
            border: 3px solid var(--violet-color);
      }

      100% {
            border: 3px solid var(--yellow);
      }
}

.tokenomics-content-box {
      background-color: #EAEAEA;
      border: 3px solid transparent;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      padding: 16px 17px 16px 14px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      transition: border 5s ease;
      -webkit-transition: border 5s ease;
      -moz-transition: border 5s ease;
      -ms-transition: border 5s ease;
      -o-transition: border 5s ease;
}

.tokenomics-content-box:hover {
      animation: gradient-border 5s infinite;
}

.tokenomics-content-box:last-child {
      margin-bottom: 0;
}

.tokenomics-content-inner-flex {
      display: flex;
      align-items: center;
      gap: 17px;
}

.tokenomics-content-color-box {
      width: 23px;
      height: 98px;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
}

.yellow-color {
      background-color: var(--yellow);
}

.cyne-color {
      background-color: var(--cyne-color);
}

.violet-color {
      background-color: var(--violet-color);
}

.pink-color {
      background-color: var(--pink);
}

.orange-color {
      background-color: var(--orange);
}

.dark-blue-color {
      background-color: #0068b9;
}

.tokenomics-content-main .tokenomics-content-title {
      font-size: 24px;
      line-height: 32px;
      font-family: 'InterDisplay-Medium';
      color: var(--main-black);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      /* margin-bottom: 22px; */

}

.tokenomics-content-main .tokenomics-content-para {
      font-size: 20px;
      line-height: 26px;
      font-family: 'InterDisplay-Light';
      color: #101111;
}

.tokenomics-content-main .tokenomics-content-lable {
      font-size: 24px;
      line-height: 32px;
      font-family: 'InterDisplay-Medium';
      color: var(--main-black);
      margin-bottom: 22px;
      text-align: center;
}

.tokenomics-content-main .tokenomics-content-digit {
      font-size: 20px;
      line-height: 26px;
      font-family: 'InterDisplay-Regular';
      color: #101111;
}

.tokenomics-wrapper .tokenomics-heading {
      font-size: 36px;
      line-height: 44px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      margin-bottom: 5px;
      text-align: center;
}

.tokenomics-wrapper .tokenomics-title {
      font-size: 73px;
      line-height: 84px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      text-align: center;
}

.tokenomics-row {
      padding-top: 50px;
      padding-left: 35px;
      padding-right: 35px;
      align-items: center;
}

/* end tokenomics section */

/* start team section */

.team-sec {
      padding: 90px 0px 163px;
      position: relative;
      z-index: 1;
}

.team-sec .team-heading {
      font-size: 107px;
      line-height: 128px;
      color: #B2B2B2;
      font-family: 'InterDisplay-ExtraBold';
      text-align: center;
      margin-bottom: 45px;

}

.team-sec .team-para {
      font-size: 20px;
      line-height: 35px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      text-align: center;
      max-width: 795px;
      margin: auto auto 95px;
}

.team-img {
      border-radius: 36px;
      -webkit-border-radius: 36px;
      -moz-border-radius: 36px;
      -ms-border-radius: 36px;
      -o-border-radius: 36px;
      max-width: 325px;
      height: 349px;
      width: 100%;
      display: block;
}

.team-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 42px;
}

.team-box .team-member-name {
      margin-top: 27px;
      margin-bottom: 18px;
      font-size: 30px;
      line-height: 38px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      text-align: center;
}

.team-box .team-member-position {
      font-size: 16px;
      line-height: 22px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      text-align: center;
}

.team-img-box {
      position: relative;
      display: inline-block;
}

.team-img-box::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      background: linear-gradient(45deg, #ff67cb, #00B4B0, #FDF2EC, #9f49a3, #F5E594);
      z-index: -1;
      border-radius: 36px;
      background-size: 300% 300%;
      animation: gradientAnimation 5s linear infinite;
      opacity: 0;
      transition: opacity 0.3s;
      -webkit-border-radius: 36px;
      -moz-border-radius: 36px;
      -ms-border-radius: 36px;
      -o-border-radius: 36px;
}

.team-img-box:hover::before {
      opacity: 1;
}

@keyframes gradientAnimation {
      0% {
            background-position: 0% 50%;
      }

      50% {
            background-position: 100% 50%;
      }

      100% {
            background-position: 0% 50%;
      }
}

/* end team section */

/* start footer */

.footer-copy-main-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 41px;
      border-top: 1px solid #2020201f;
}

.footer-copy-main-flex .footer-copy-nav {
      display: flex;
      align-items: center;
      gap: 60px;
      padding: 0;
      margin: 0;
      list-style-type: none;
}

.footer-copy-nav .footer-copy-nav-li {
      max-width: max-content;
      padding: 0;
      margin: 0;
}

.footer-copy-nav .footer-copy-nav-link {
      font-size: 20px;
      line-height: 28px;
      color: #202020;
      font-family: 'InterDisplay-Regular';
      text-decoration: none;
}

.footer-copy-main .footer-copy-right-text {
      font-size: 20px;
      line-height: 28px;
      color: #202020;
      font-family: 'InterDisplay-Light';
      text-decoration: none;
}

.footer-nav-main {
      margin-bottom: 53px;
      margin-top: 116px;
}

.footer-nav-main .footer-nav-ul {
      padding: 0;
      margin: 0;
      display: flex;
      gap: 52px;
      align-items: center;
      list-style-type: none;
      flex-wrap: wrap;
}

.footer-nav-ul .footer-nav-li {
      max-width: max-content;
      padding: 0;
      margin: 0;
}

.footer-nav-ul .footer-nav-link {
      font-size: 24px;
      line-height: 32px;
      font-family: 'InterDisplay-Regular';
      color: #202020;
      text-decoration: none;
}

.footer-col .footer-titles {
      font-size: 200px;
      line-height: 200px;
      font-family: 'InterDisplay-SemiBold';
      color: #C7C7C7;
      margin-bottom: 185px;
      margin-top: 65px;
}

.footer {
      background-color: var(--main-white);
      padding: 72px 0px 62px 0px;
      position: relative;
      z-index: 1;
}

.footer-social-main {
      display: flex;
      align-items: center;
      gap: 37px;
      justify-content: flex-end;
      margin-top: 105px;
      margin-bottom: 53px;
}

.footer-social-main .footer-social-titles {
      font-size: 20px;
      line-height: 28px;
      color: #202020;
      font-family: 'InterDisplay-Regular';
}

.footer-social-nav-ul {
      display: flex;
      align-items: center;
      gap: 14px;
      padding: 0;
      margin: 0;
      list-style-type: none;
}

.footer-social-nav-ul .footer-social-nav-li {
      max-width: max-content;
      padding: 0;
      margin: 0;
}

.footer-social-link {
      text-decoration: none;
}

.social-icon {
      height: 24px;
      width: 24px;
      object-fit: contain;
}

.footer-form-main .footer-form-titles {
      font-size: 48px;
      line-height: 57px;
      color: #202020;
      font-family: 'InterDisplay-Medium';
      margin-bottom: 24px;
}

.footer-form-main .footer-para {
      font-size: 24px;
      line-height: 39px;
      color: #202020;
      font-family: 'InterDisplay-Regular';
      margin-bottom: 39px;
      max-width: 439px;
      width: 100%;
}

.footer-content-main .footer-btn,
.footer-content-main .footer-btn:hover {
      background-color: #202020;
      color: var(--main-white);
      border-radius: 45px;
      -webkit-border-radius: 45px;
      -moz-border-radius: 45px;
      -ms-border-radius: 45px;
      -o-border-radius: 45px;
      padding: 13px 20px;
      border: none;
      font-size: 20px;
      line-height: 28px;
      font-family: 'InterDisplay-Regular';
      display: flex;
      align-items: center;
      width: 100%;
      text-transform: capitalize;
}

.footer-form-flex {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      align-items: flex-start;
      margin-bottom: 21px;
}

/* end footer */

/* start roadmap section */
.roadmap-slider .owl-stage-outer {
      margin-top: -116px;
      padding-top: 116px;
}

.roadmap-sec {
      padding: 100px 0px 90px;
      background-color: var(--main-white);
}

.roadmap-sec .roadmap-heading {
      font-size: 29px;
      line-height: 38px;
      color: #8A9195;
      font-family: 'InterDisplay-Regular';
      margin-bottom: 22px;
}

.roadmap-sec .roadmap-sub-title {
      font-size: 55px;
      line-height: 72px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      max-width: 747px;
      width: 100%;
      margin-bottom: 50px;
}

.roadmap-box {
      border: 1px solid #2b2c3875;
      padding: 76px 36px 40px 51px;
      background-color: #FDF2EC;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      height: 315px;
}

.roadmap-box .roadmap-content-title {
      color: var(--main-black);
      font-family: 'InterDisplay-Medium';
      /* font-size: 30px;
      line-height: 36px; */
      font-size: 19px;
      line-height: 28px;
      margin-left: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
}

.roadmap-box .roadmap-content-para {
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      font-size: 18px;
      line-height: 35px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
}

.roadmap-dot-main {
      position: relative;
      display: flex;
      margin-bottom: 30px;
      align-items: center;
}

.roadmap-box .roadmap-list {
      padding: 0;
      padding-left: 22px;
      margin: 0;
      list-style-type: disc;
}

.roadmap-list .roadmap-list-item {
      padding: 0;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 10px;
      text-decoration: none;
      /* position: relative; */
      /* padding-left: 20px; */
}

.roadmap-list .roadmap-list-item::marker {
      color: #662121;
}

/* .roadmap-list .roadmap-list-item::after{
      content: '';
      position: absolute;
      left: 0;
      top: 30%;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      background-color:#662121;
} */
.roadmap-box .roadmap-year-text {
      color: var(--main-black);
      font-family: 'InterDisplay-SemiBold';
      font-size: 20px;
      line-height: 26px;
      position: absolute;
      top: -145px;
      left: -37px;
}

.roadmap-slider-main {
      padding-left: 244px;
      padding-top: 116px;
}

.roadmap-dot {
      background-color: #DF86AA;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      position: relative;
}

.roadmap-dot::after {
      background-color: #292A37;
      height: 120px;
      width: 1px;
      content: '';
      position: absolute;
      left: 50%;
      bottom: 16px;
}

.owl-stage {
      padding-left: 0px !important;
}



.roadmap-slider-main .roadmap-slider .owl-nav .owl-prev,
.roadmap-slider-main .roadmap-slider .owl-nav .owl-next,
.roadmap-slider-main .roadmap-slider .owl-nav .owl-prev:hover,
.roadmap-slider-main .roadmap-slider .owl-nav .owl-next:hover {
      width: 55px;
      height: 55px;
      margin: 0px 5px;
      background: #F9F9F9;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
}

.roadmap-slider-main .roadmap-slider .owl-nav .owl-prev {
      top: -26%;
      right: 24.8%;
}

.roadmap-slider-main .roadmap-slider .owl-nav .owl-next {
      top: -26%;
      right: 20%;
}

.roadmap-slider-main .roadmap-slider .owl-prev img,
.roadmap-slider-main .roadmap-slider .owl-next img {
      width: 16px;
      height: 18px;
      object-fit: contain;
}

/* .section-wrapper {
      position: relative;
} */

/* .section-wrapper::after {
      background-color: #FDF2EC;
      content: '';
      position: absolute;
      width: 33%;
      height: 98%;
      right: 0;
      top: 17%;
} */

/* end roadmap section */


/* start Faq section */
.faq-sec {
      background-color: #FDF2EC;
      padding: 117px 0px 118px;
      position: relative;
}

.faq-sec .faq-heading {
      font-size: 29px;
      line-height: 38px;
      color: #8A9195;
      font-family: 'InterDisplay-Regular';
      margin-bottom: 20px;
}

.faq-sec .faq-sub-title {
      font-size: 55px;
      line-height: 72px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
}

.faq-flex {
      display: flex;
      gap: 141px;
}

.faq-left-main {
      max-width: 30%;
      width: 100%;
      flex: 0 0 30%;
}

.faq-right-main {
      flex: 1;
}

.accrodian-main .accordian {
      box-shadow: none;
      border-radius: 12px !important;
      margin-bottom: 32px;
}

.accrodian-main .accordian::before {
      display: none;
}

.accordian .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
}

.accordian .accordian-title {
      color: var(--main-black);
      font-size: 28px;
      line-height: 40px;
      font-family: 'InterDisplay-Regular';
      /* max-width: 635px; */
      width: 100%;
      padding-right: 35px;
      /* text-transform: capitalize; */
}

.accordian .MuiAccordionSummary-root.Mui-expanded {
      padding-bottom: 0;
}

.accordian .accordian-details {
      position: relative;
      padding: 20px 42px 30px;
}

.accordian .MuiAccordionSummary-content {
      margin: 0;
}

.accordian-details .accodian-details-para {
      color: #727885;
      font-size: 20px;
      line-height: 40px;
      font-family: 'InterDisplay-Regular';
}

.accordian .accordian-details::after {
      content: '';
      bottom: 0;
      height: 6px;
      width: 100%;
      left: 0;
      right: 0;
      background-image: linear-gradient(45deg, #FF99EA, #F5E594);
      position: absolute;
}

.accordian .MuiAccordionSummary-root {
      padding: 32px 42px;
      align-items: flex-start;
      position: relative;
}

.accordian .MuiAccordionSummary-root.Mui-expanded {
      min-height: 40px;
}

.MuiAccordionSummary-expandIconWrapper svg {
      display: none;
}


.accordian .MuiAccordionSummary-root::after {
      content: '';
      position: absolute;
      right: 42px;
      top: 21%;
      background-image: url(../../assets/images/svg/round-plus.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 65px;
      height: 64px;
}

.faq-coin-img-main {
      position: absolute;
      bottom: -9%;
      left: 11%;
      animation: bounce 1s infinite alternate;
      -webkit-animation: bounce 1s infinite alternate;
}

@keyframes bounce {
      0% {
            transform: translateY(0);
      }

      100% {
            transform: translateY(-10px);
            /* Adjust the bounce height as needed */
      }
}

.faq-coin-img {
      width: 498px;
      height: 304px;
      object-fit: contain;
}

.token-chart-main .token-chart-value-lable {
      font-size: 24px;
      line-height: 32px;
      color: var(--main-black);
      font-family: 'InterDisplay-Regular';
      margin-bottom: 13px;
      text-align: center;
}

.token-chart-main .token-chart-value-text {
      font-size: 25px;
      line-height: 32px;
      color: var(--main-black);
      font-family: 'InterDisplay-Bold';
      text-align: center;
}

.token-chart-main {
      position: relative;
}

.token-chart-value-box {
      position: absolute;
      top: 42%;
      left: 35%;
      max-width: 211px;
      margin: auto;
      display: flex;
      flex-direction: column;
}

/* end Faq section */

/* start tops croll section */

.top-scroll-box {
      display: none;
      position: fixed;
      bottom: 58px;
      right: 82px;
      z-index: 9;
}

.top-scroll-box-show {
      display: block;
}

.top-scroll-box .top-scroll-btn {
      background-color: #D9D9D9;
      height: 71px;
      min-width: 71px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
}

.top-scroll-box .top-scroll-btn:hover {
      background-image: linear-gradient(45deg, #ff67cb, #ffcc67);
}

.top-scroll-icon {
      height: 26px;
      width: 26px;
      object-fit: contain;
}

/* end tops croll section */
.mobile-menu-icon {
      height: 25px;
      width: 25px;
      object-fit: contain;
}

.mobile-logo-main {
      display: none;
}

.mobile-logo-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      margin: 0px 20px 10px;
      border-bottom: 1px solid #66212126;
}

.mobile-logo-box {
      display: flex;
      align-items: center;
      gap: 10px;
}

.mobile-logo-box .mobile-logo-text {
      color: #662121;
      font-size: 26px;
      line-height: 39px;
      font-family: 'InterDisplay-SemiBold';
}

.mobile-logo {
      height: 40px;
      width: 40px;
      object-fit: contain;
}

.mobile-logo-flex .mobile-menu-btn {
      min-width: 30px;
      padding: 0;
      justify-content: flex-end;
}

.mobile-menu-close-icon {
      height: 25px;
      width: 25px;
      object-fit: contain;
}

.footer-token-img {
      height: 327px;
      width: 100%;
      object-fit: contain;
}

.comin-modal-img {
      height: 200px;
      width: 100%;
      object-fit: contain;
}

.coming-soon-logo-box {
      margin: auto;
}

.coming-soon-logo {
      height: 60px;
      width: 100%;
      object-fit: contain;
}

.coming-modal-inner .coming-soon-text {
      font-size: 30px;
      line-height: 49px;
      font-family: 'InterDisplay-SemiBold';
      text-align: center;
      text-transform: uppercase;
      color: #662121;
      animation: zoomInOut 2s ease-in-out infinite;
      margin: 30px 0px 0px;
      overflow: hidden;
      -webkit-animation: zoomInOut 2s ease-in-out infinite;
}

@keyframes zoomInOut {

      0%,
      100% {
            transform: scale(0.8);
            -webkit-transform: scale(0.8);
            -moz-transform: scale(0.8);
            -ms-transform: scale(0.8);
            -o-transform: scale(0.8);
      }

      50% {
            transform: scale(0.9);
            -webkit-transform: scale(0.9);
            -moz-transform: scale(0.9);
            -ms-transform: scale(0.9);
            -o-transform: scale(0.9);
      }
}

.roadmap-card-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
}

.header-nav-main .header-nav-li.active .header-nav-link {
      color: #662121;
      font-family: 'InterDisplay-Bold';
}